import * as Sentry from '@sentry/react';
import React from 'react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { env } from 'src/env';

export const sentryConfig = {
    dsn: env.REACT_APP_SENTRY_DSN,
    normalizeDepth: 5, // Redux state context depth.
    integrations: [
        new Sentry.BrowserTracing({
            // 'tracePropagationTargets' control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
            ],
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        }),
        new Sentry.Replay(),
    ],
    environment: env.REACT_APP_ENV,

    // Performance Monitoring
    tracesSampleRate: env.REACT_APP_ENV === 'production' ? 0.5 : 1.0, // Capture % of the transactions

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};
