import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});
interface ConfirmationDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    message?: string;
    actionFn: () => void;
    dialogChild?: React.ReactNode;
    cancelText: string;
    proceedText: string;
    showProceed: boolean;
    showCancel: boolean;
    requireInteraction: boolean;
}

export default function ConfirmationDialog({
    //TODO: ConfirmationDialog is prob not the best name for this
    open,
    onClose,
    title,
    message,
    actionFn,
    dialogChild,
    cancelText,
    proceedText,
    showProceed,
    showCancel,
    requireInteraction,
}: ConfirmationDialogProps) {
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={requireInteraction ? undefined : onClose}
                aria-describedby='alert-dialog-slide-description'
            >
                <DialogTitle variant='h3'>{title}</DialogTitle>
                <DialogContent>
                    {dialogChild ? (
                        dialogChild
                    ) : (
                        <DialogContentText id='alert-dialog-slide-description'>
                            {message}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    {showCancel && <Button onClick={onClose}>{cancelText}</Button>}
                    {showProceed && <Button onClick={actionFn}>{proceedText}</Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}
