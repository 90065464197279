import { createSlice } from '@reduxjs/toolkit';
import { persistAuth } from 'src/features/authentication/services/persistAuth';
import { syncUserSlice } from 'src/features/authentication/services/syncUserSlice';
import { IUserSlice } from 'src/models/users';
import { RootState } from '../store';

const initialState: IUserSlice = {
    user: null,
    status: 'idle',
    accessToken: null,
    refreshToken: null,
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        /**
         * Dispatch resetUser to reset the redux store. Handy when clearing user sessions when logging out.
         */
        resetUser() {
            // console.log('resetting store');
        },
    },
    extraReducers(builder) {
        builder
            //###########################       Sync       ##############################
            .addCase(syncUserSlice.fulfilled, (state, action) => {
                state.user = action.payload.user;
            })
            //###########################       Persist       ###########################
            .addCase(persistAuth.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(persistAuth.fulfilled, (state, action) => {
                state.status = 'authenticated';
                state.accessToken = action.payload.accessToken;
                state.user = action.payload.user;
            })
            .addCase(persistAuth.rejected, (state, action) => {
                state.status = 'error';
                state.accessToken = null;
                state.refreshToken = null;
                state.user = null;
                state.error = action.error.message;
            });
    },
});

export default userSlice.reducer;

export const selectUser = (state: RootState): typeof initialState => state.user;
