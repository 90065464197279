import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { persistAuth } from 'src/features/authentication/services/persistAuth';
import { useGetUserByIdQuery } from 'src/services/restApi/queries/users.service';
import { useAppDispatch } from './hooks';

export default function useCheckProfileStatus() {
    const auth = useAuth0();
    const dispatch = useAppDispatch();
    const {
        data: user,
        isFetching
    } = useGetUserByIdQuery(
        { userId: auth.user?.sub ?? '' },
        { pollingInterval: 1000 },
    );

    useEffect(() => {
        const prep = async () => {
            dispatch(
                persistAuth({
                    user: { ...auth.user, dbUser: user?.value },
                    accessToken: await auth.getAccessTokenSilently(),
                }),
            );
        }
        prep();
    }, [isFetching]);
}
