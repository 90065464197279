import { IProductConfig, IProducts } from 'src/models/product';

export const productConfig: Record<IProducts, IProductConfig> = {
    //#region pulse
    PULSE: {
        displayName: 'Pulse',
        name: 'pulse',
        supportMail: 'cs@playpulse.app',
        privacyPolicyUrl: 'https://register.playpulse.app/privacy-policy/',
        termsAndConditionsUrl: 'https://register.playpulse.app/terms-and-conditions/',
        local: {
            gameUrl: 'https://blockpulse.dev/',
            basecampUrl: 'http://localhost:3000',
            basecampAuth0RedirectUrl: 'http://localhost:3000/auth/login',
            basecampRedirectSuccess: 'http://localhost:3000/status/success',
            basecampRedirectFailure: 'http://localhost:3000/status/failure',
            basecampRedirectCancel: 'http://localhost:3000/status/cancel'
        },
        development: {
            gameUrl: 'https://blockpulse.dev/',
            basecampUrl: 'https://mms.blockpulse.dev/',
            basecampAuth0RedirectUrl: 'https://mms.blockpulse.dev/auth/login',
            basecampRedirectSuccess: 'https://mms.blockpulse.dev/status/success',
            basecampRedirectFailure: 'https://mms.blockpulse.dev/status/failure',
            basecampRedirectCancel: 'https://mms.blockpulse.dev/status/cancel'
        },
        staging: {
            gameUrl: 'https://blockpulse.dev/',
            basecampUrl: 'https://mms.blockpulse.dev/',
            basecampAuth0RedirectUrl: 'https://mms.blockpulse.dev/auth/login',
            basecampRedirectSuccess: 'https://mms.blockpulse.dev/status/success',
            basecampRedirectFailure: 'https://mms.blockpulse.dev/status/failure',
            basecampRedirectCancel: 'https://mms.blockpulse.dev/status/cancel'
        },
        production: {
            gameUrl: 'https://game.playpulse.app/',
            basecampUrl: 'https://mms.playpulse.app/',
            basecampAuth0RedirectUrl: 'https://mms.playpulse.app/auth/login',
            basecampRedirectSuccess: 'https://mms.playpulse.app/status/success',
            basecampRedirectFailure: 'https://mms.playpulse.app/status/failure',
            basecampRedirectCancel: 'https://mms.playpulse.app/status/cancel'
        },
        favicon: '/static/images/brand/pulse/favicon.ico',
        logoDark: '/static/images/brand/pulse/logo-light.svg',
        logoLight: '/static/images/brand/pulse/logo-dark.svg',
    },
    // #endregion pulse
    //#region boxwind
    BOXWIND: {
        displayName: 'Boxwind',
        name: 'boxwind',
        supportMail: 'cs@playboxwind.app',
        privacyPolicyUrl: 'https://playboxwind.app/privacy',
        termsAndConditionsUrl: 'https://playboxwind.app/terms',
        local: {
            gameUrl: 'https://blockpulse.dev/',
            basecampUrl: 'http://localhost:3000',
            basecampAuth0RedirectUrl: 'http://localhost:3000/auth/login',
            basecampRedirectSuccess: 'http://localhost:3000/status/success',
            basecampRedirectFailure: 'http://localhost:3000/status/failure',
            basecampRedirectCancel: 'http://localhost:3000/status/cancel'
        },
        development: {
            gameUrl: 'https://blockpulse.dev/',
            basecampUrl: 'https://mms.blockpulse.dev/',
            basecampAuth0RedirectUrl: 'https://mms.blockpulse.dev/auth/login',
            basecampRedirectSuccess: 'https://mms.blockpulse.dev/status/success',
            basecampRedirectFailure: 'https://mms.blockpulse.dev/status/failure',
            basecampRedirectCancel: 'https://mms.blockpulse.dev/status/cancel'
        },
        staging: {
            gameUrl: 'https://blockpulse.dev/',
            basecampUrl: 'https://mms.blockpulse.dev/',
            basecampAuth0RedirectUrl: 'https://mms.blockpulse.dev/auth/login',
            basecampRedirectSuccess: 'https://mms.blockpulse.dev/status/success',
            basecampRedirectFailure: 'https://mms.blockpulse.dev/status/failure',
            basecampRedirectCancel: 'https://mms.blockpulse.dev/status/cancel'
        },
        production: {
            gameUrl: 'https://blockpulse.dev/',
            basecampUrl: 'https://mms.blockpulse.dev/',
            basecampAuth0RedirectUrl: 'https://mms.blockpulse.dev/auth/login',
            basecampRedirectSuccess: 'https://mms.blockpulse.dev/status/success',
            basecampRedirectFailure: 'https://mms.blockpulse.dev/status/failure',
            basecampRedirectCancel: 'https://mms.blockpulse.dev/status/cancel'
        },
        favicon: '/static/images/brand/octoco/favicon.ico',
        logoDark: '/static/images/brand/octoco/Octoco Logo 8.svg',
        logoLight: '/static/images/brand/octoco/Octoco Logo 1.svg',
    },
    // #endregion boxwind
}