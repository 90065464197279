import { FC, Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AdminGuard } from 'src/Guards/adminGuard/AdminGuard';
import AuthGuard from 'src/Guards/authGuard/AuthGuard';
import SuspenseLoader from 'src/components/SuspenseLoader';
import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';
import pages from './routes';
import { UserGuard } from 'src/Guards/userGuard/UserGuard';

const Loader = (Component: FC) => (props: any) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

// Pages
const Home = Loader(lazy(() => import('src/pages/Home/Home')));
const ProfileView = Loader(lazy(() => import('src/pages/ProfileView/ProfileView')));
const ProfileEdit = Loader(lazy(() => import('src/pages/ProfileEdit/ProfileEdit')));
const Account = Loader(lazy(() => import('src/pages/Account/Account')));
const Users = Loader(lazy(() => import('src/pages/Users/Users')));
const Sumsub = Loader(lazy(() => import('src/pages/Sumsub/Sumsub')));
const Login = Loader(lazy(() => import('src/pages/Login/Login')));
const LoginAdmin = Loader(lazy(() => import('src/pages/LoginAdmin/LoginAdmin')));
// const UserPendingWithdrawals = Loader(lazy(() => import('src/pages/UserPendingWithdrawals/UserPendingWithdrawals')));

// Admin Pages
const Transactions = Loader(lazy(() => import('src/pages/Transactions/Transactions')));
const AllPendingWithdrawals = Loader(lazy(() => import('src/pages/AllPendingWithdrawals/AllPendingWithdrawals')));

// Status
const Status404 = Loader(lazy(() => import('src/pages/Status/Status404/Status404')));
const Unverified = Loader(lazy(() => import('src/pages/Status/Unverified/Unverified')));
const ProfileIncomplete = Loader(lazy(() => import('src/pages/Status/ProfileIncomplete/ProfileIncomplete')));
const Status500 = Loader(lazy(() => import('src/pages/Status/Status500/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/pages/Status/ComingSoon/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/pages/Status/Maintenance/Maintenance')));
const StatusTransactionSuccess = Loader(
    lazy(() => import('src/pages/Status/TransactionSuccess/TransactionSuccess')),
);
const StatusTransactionFailure = Loader(
    lazy(() => import('src/pages/Status/TransactionFailure/TransactionFailure')),
);
const StatusTransactionCancel = Loader(
    lazy(() => import('src/pages/Status/TransactionCancel/TransactionCancel')),
);

const routes: RouteObject[] = [
    {
        path: '',
        /**
         * All children within this element will NOT have a Sidebar and top Navbar
         * All children within this element does not need to be authenticated to access
         */
        element: <BaseLayout />,
        children: [
            //#region Base
            {
                path: '/',
                element: <Navigate to={pages.home.path} replace />,
            },
            //#endregion Base
            //#region Auth
            {
                path: pages.auth.root,
                children: [
                    {
                        path: '',
                        element: <Login />,
                    },
                    {
                        path: pages.auth.login.name,
                        element: <Login />,
                    },
                    {
                        path: pages.auth.loginAdmin.path,
                        element: <LoginAdmin />,
                    },
                ],
            },
            //#endregion Auth
            //#region Status
            {
                path: pages.status.root,
                children: [
                    {
                        path: '',
                        element: <Navigate to='404' replace />,
                    },
                    {
                        path: pages.status.status404.name,
                        element: <Status404 />,
                    },
                    {
                        path: pages.status.status500.name,
                        element: <Status500 />,
                    },
                    {
                        path: pages.status.statusMaintenance.name,
                        element: <StatusMaintenance />,
                    },
                    {
                        path: pages.status.statusComingSoon.name,
                        element: <StatusComingSoon />,
                    },
                    {
                        path: pages.status.statusTransactionSuccess.name,
                        element: <StatusTransactionSuccess />,
                    },
                    {
                        path: pages.status.statusTransactionFailure.name,
                        element: <StatusTransactionFailure />,
                    },
                    {
                        path: pages.status.statusTransactionCancel.name,
                        element: <StatusTransactionCancel />,
                    },
                ],
            },
            //#endregion Status
            //#region NotFound
            {
                path: '*',
                element: <Status404 />,
            },
            //#endregion NotFound
        ],
    },
    {
        path: '',
        element: (
            /**
             * All children with this element will have a Sidebar and top Navbar
             * AuthGuard checks that the user is logged in before granting access to its children pages
             */
            <AuthGuard>
                <SidebarLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <Navigate to={pages.home.name} replace />,
            },

            {
                path: '/profile',
                element: <Navigate to={'view/current-user'} replace />,
            },

            {
                path: pages.unverified.name,
                element: <Unverified />,
            },
            {
                path: pages.profileIncomplete.name,
                element: <ProfileIncomplete />,
            },
            {
                path: pages.home.name,
                element: <Home />,
            },
            {
                path: pages.profileView.path,

                element: <UserGuard>
                    <ProfileView />
                </UserGuard>,
            },
            {
                path: pages.profileEdit.path,

                element: <UserGuard>
                    <ProfileEdit />
                </UserGuard>,
            },
            {
                path: pages.account.name,
                //account does not need a guard, as it is viewed without a param
                element: <Account />,
            },
            {
                path: pages.admin.root,
                element: <AdminGuard />,
                children: [
                    {
                        path: pages.admin.transactions.path,
                        element: <Transactions />,
                    },
                    {
                        path: pages.admin.users.path,
                        element: <Users />,
                    },
                    {
                        path: pages.admin.pendingWithdrawals.path,
                        element: <AllPendingWithdrawals />,
                    },
                ],
            },
            {
                path: pages.sumsub.name,
                element: <Sumsub />,
            },
        ],
    },
];

export default routes;
