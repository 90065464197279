import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store'

const initialState: { theme: string } = {
    theme: localStorage.getItem('basecampThemeType') ?? 'dark',
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme(state, action: PayloadAction<{ theme: string }>) {
            const { theme } = action.payload;
            state.theme = theme;
        },
        toggleTheme(state, action) {
            if (state.theme === 'dark') state.theme = 'light';
            else state.theme = 'dark';
        },
    },
});

export default themeSlice.reducer;
export const { setTheme, toggleTheme } = themeSlice.actions;

export const selectTheme = (state: RootState) => state.theme.theme;
