import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/hooks';
import Status404 from 'src/pages/Status/Status404/Status404';

export const AdminGuard = () => {
    const auth0user = useAppSelector((state) => state.user);
    const isAdmin = auth0user.user?.dbUser.role === 'ADMIN';

    return isAdmin ? (
        <Outlet />
    ) : (
        <Box mt={10}>
            <Status404 />
        </Box>
    );
};
