import { useAuth0 } from '@auth0/auth0-react';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, CircularProgress, Container, Paper, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import Logo from 'src/components/LogoSign';
import { ProductContext } from 'src/contexts/ProductContext';
import { Logout } from 'src/features/authentication/components/logout';
import useCheckProfileStatus from 'src/hooks/useCheckProfileStatus';

const OverviewWrapper = styled(Box)(
    () => `
      overflow: auto;
      flex: 1;
      overflow-x: hidden;
      align-items: center;
  `
);

const PageContent = () => {
    const auth = useAuth0();
    useCheckProfileStatus();
    const { product } = useContext(ProductContext);

    const handleCompleteProfileRedirect = () => {
        if (auth.user?.sub) {
            window.location.href = `${product.productUrls.gameUrl}complete-profile`;
        } else {
            toast.error('An error occurred redirecting you to the complete profile page');
        }
    };

    return (
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
            <Paper sx={{ p: 3 }}>
                <Typography sx={{ mb: 2 }} variant="h5">
                    {auth.isLoading ? <Skeleton /> : `${auth.user?.email}.`}
                </Typography>
                <Typography sx={{ mb: 2 }} variant="h3">
                    {auth.isLoading ? <Skeleton /> : 'Your profile has not been completed yet'}
                </Typography>
                <Typography sx={{ lineHeight: 1.5, pb: 1 }} variant="body1">
                    {auth.isLoading ? (
                        <Skeleton />
                    ) : (
                        'Please visit the link below and complete your profile.'
                    )}
                </Typography>
                <Typography variant="body1">
                    Once your profile is complete, you will be able to access the platform.
                </Typography>
                <Box pt={3}>
                    <LoadingButton
                        loading={false}
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => handleCompleteProfileRedirect()}
                        startIcon={<HowToRegIcon />}
                    >
                        Complete Profile
                    </LoadingButton>
                </Box>
                <Box pt={3}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        Not your email address or already completed your profile? Log out and back
                        in again.
                    </Typography>
                    <Logout />
                </Box>
            </Paper>
        </Container>
    );
};

function ProfileIncomplete() {
    const auth = useAuth0();

    return (
        <OverviewWrapper>
            <Helmet>
                <title>Complete Profile</title>
            </Helmet>
            <Container maxWidth="lg">
                <Box display="flex" justifyContent="center" py={5} alignItems="center">
                    <Logo width={230} />
                </Box>

                {auth.isLoading ? <CircularProgress /> : <PageContent />}
            </Container>
        </OverviewWrapper>
    );
}

export default ProfileIncomplete;
