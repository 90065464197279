import Groups2TwoToneIcon from '@mui/icons-material/Groups2TwoTone';
import { Avatar, Box, Stack, Tooltip, Typography, styled } from '@mui/material';
import { useAppSelector } from 'src/hooks/hooks';
import PersonIcon from '@mui/icons-material/Person2';
import { getEllipsisTxt } from 'src/utils/formatters';

const CardWrapper = styled(Box)(
    () => `
            height: 113px;
            width: 262px;
            display: flex;
          `,
);

const UserInfo = () => {
    const auth0user = useAppSelector((state) => state.user);

    return (
        <CardWrapper >
            <Stack
                direction='column'
                spacing={1.5}
                alignItems='center'
                sx={{
                    width: '100%',
                }}
            >
                <Box>
                    {auth0user?.user?.dbUser?.role === 'ADMIN' && (
                        <Tooltip arrow placement='top' title='Admin User'>
                            <Groups2TwoToneIcon fontSize='large' />
                        </Tooltip>
                    )}
                    <Avatar
                        src={auth0user?.user?.picture ?? ''}
                        imgProps={{
                            sx: {
                                referrerpolicy: 'no-referrer',
                                maskImage:
                                    'radial-gradient(circle, white 66%, rgba(255, 254, 255, 0.5) 10%)',
                            },
                        }}
                        sx={{
                            alt: 'Profile Picture of User',
                            height: 65,
                            width: 65,
                        }}
                    >
                        <PersonIcon sx={{ fontSize: 50 }} color='info' />
                    </Avatar>
                </Box>
                <Stack spacing={0.5}>
                    <Stack>
                        <Typography variant='h6'>{getEllipsisTxt(auth0user?.user?.dbUser?.firstName ?? '', 20)}</Typography>
                        {/* {auth0user?.user?.dbUser?.email && (
                            <Typography variant='body1'>
                                {auth0user?.user?.dbUser?.email ?? '--'}
                            </Typography>
                        )} */}
                    </Stack>
                </Stack>
            </Stack>
        </CardWrapper>
    );
};

export default UserInfo;
