import { useAuth0 } from '@auth0/auth0-react';
import { Suspense, useEffect } from 'react';
import { toast } from 'react-toastify';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { Login } from 'src/features/authentication/components/login';
import { persistAuth } from 'src/features/authentication/services/persistAuth';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import LoadingStatusWrapper from 'src/pages/Status/LoadingStatus/LoadingStatus';
import ProfileIncomplete from 'src/pages/Status/ProfileIncomplete/ProfileIncomplete';
import Unverified from 'src/pages/Status/Unverified/Unverified';

export default function AuthGuard({ children }: { children: JSX.Element }) {
    const auth = useAuth0();
    const dispatch = useAppDispatch();
    const auth0user = useAppSelector((state) => state.user);
    useEffect(() => {
        if (auth.error) {
            toast.error(
                'There has been an error getting authentication details. Please try logging in'
            );
        }

        const prep = async () => {
            try {
                await dispatch(
                    persistAuth({
                        user: auth.user,
                        accessToken: await auth.getAccessTokenSilently(),
                    })
                );
            } catch (error) {
                toast.error(
                    'There has been an error getting authentication details. Please try logging in'
                );
            }
        };

        if (!auth.isLoading && auth.isAuthenticated) {
            prep();
        }

        if (auth.error) {
            toast.error(
                'There has been an error getting authentication details. Please try logging in'
            );
        }
    }, [auth]);

    if (auth.isLoading) {
        return <Suspense fallback={<SuspenseLoader />}>{children}</Suspense>;
    }

    if (!auth.isAuthenticated) {
        return <Login />;
    }

    if (auth0user.user?.dbUser?.emailVerified === undefined) {
        return <LoadingStatusWrapper />;
    }

    if (
        !auth0user.user?.dbUser?.emailVerified &&
        !auth.isLoading &&
        auth.user &&
        !auth.user?.email_verified
    ) {
        return <Unverified />;
    }

    if (!auth.isLoading && auth.user && !auth0user.user?.dbUser?.profileComplete) {
        return <ProfileIncomplete />;
    }

    return children;
}
