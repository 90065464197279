import { useContext, useEffect } from 'react';

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Box, IconButton, Tooltip, alpha, lighten, styled, useTheme } from '@mui/material';
import { SidebarContext } from 'src/contexts/SidebarContext';

import { LoadingButton } from '@mui/lab';
import appConfig from 'src/config/appConfig';
import { ProductContext } from 'src/contexts/ProductContext';
import HeaderButtons from './Buttons';
import { T, useT } from '@transifex/react';
import { setLanguageInUrl } from 'src/utils/localizationHelpers';
import { tx } from '@transifex/native';

const HeaderWrapper = styled(Box)(
    ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background ?? '#111633', 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values[appConfig.breakMobileView]}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`,
);

function Header() {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
    const theme = useTheme();
    const { product } = useContext(ProductContext)
    const t = useT();

    useEffect(() => {
        const handleLanguage = () =>{
            const storedLang = localStorage.getItem('lang');
            const urlLang = new URLSearchParams(window.location.search).get('lang');
    
            const lang = urlLang || storedLang;
    
            if (lang === null || (lang !== 'fa' && lang !== 'ru')) {
                localStorage.setItem('lang', 'en');
                setLanguageInUrl('en');
                tx.setCurrentLocale('en');
            } else {
                localStorage.setItem('lang', lang);
                setLanguageInUrl(lang);
                tx.setCurrentLocale(lang);
            }
        }
        handleLanguage()
    }, []);

    return (
        <HeaderWrapper
            display='flex'
            alignItems='center'
            style={{ justifyContent: 'flex-end' }}
            sx={{
                boxShadow:
                    theme.palette.mode === 'dark'
                        ? `0 1px 0 ${alpha(
                            lighten(theme.colors.primary.main, 0.7),
                            0.15,
                        )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
                        : `0px 2px 8px -3px ${alpha(
                            theme.colors.alpha.black[100],
                            0.2,
                        )}, 0px 5px 22px -4px ${alpha(theme.colors.alpha.black[100], 0.1)}`,
            }}
        >
            <Box display='flex' alignItems='center' sx={{
                justifyContent: { xs: 'space-between' },
                width: { xs: '100%', sm: 'inherit' }
            }}>
                <HeaderButtons />
                <Tooltip title={t('Go back to the Pulse Lobby')} placement='bottom' sx={{ float: 'center' }}>
                    <LoadingButton variant='contained' onClick={() => {
                        window.location.href = product.productUrls.gameUrl
                    }}>
                        <T _str='Pulse Lobby' />
                    </LoadingButton>
                </Tooltip>
                <Box
                    component='span'
                    sx={{
                        ml: 2,
                        display: { [appConfig.breakMobileView]: 'none', xs: 'inline-block' },
                    }}
                >
                    <Tooltip arrow title='Toggle Menu'>
                        <IconButton color='primary' onClick={toggleSidebar}>
                            {!sidebarToggle ? (
                                <MenuTwoToneIcon fontSize='small' />
                            ) : (
                                <CloseTwoToneIcon fontSize='small' />
                            )}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </HeaderWrapper >
    );
}

export default Header;
