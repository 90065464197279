import { useEffect, useState } from 'react';
import { useAppSelector } from './hooks';

interface IUseIsCurrentUserProps {
    userId?: string;
}

export default function useIsCurrentUser({ userId }: IUseIsCurrentUserProps) {
    const [isCurrentUser, setIsCurrentUser] = useState<boolean>(true);
    const auth0user = useAppSelector((state) => state.user);

    useEffect(() => {
        if (userId === 'current-user') {
            setIsCurrentUser(true);
        } else if (userId === auth0user?.user?.sub) {
            setIsCurrentUser(true);
        } else {
            setIsCurrentUser(false);
        }
    }, [userId]);

    return { isCurrentUser, auth0user };
}
