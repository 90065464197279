import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import { Box, Divider, Drawer, alpha, darken, lighten, styled, useTheme } from '@mui/material';

import Logo from 'src/components/LogoSign';
import ThemeSwitch from 'src/components/ThemeSwitch/ThemeSwitch';
import appConfig from 'src/config/appConfig';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 8px;
`,
);

function Sidebar() {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
    const closeSidebar = () => toggleSidebar();
    const theme = useTheme();

    return (
        <>
            <SidebarWrapper
                sx={{
                    display: {
                        xs: 'none',
                        [appConfig.breakMobileView]: 'inline-block',
                    },
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    background:
                        theme.palette.mode === 'dark'
                            ? alpha(lighten(theme.header.background ?? '#111633', 0.1), 0.5)
                            : darken(theme.sidebar.background ?? '#FFFFFF', 0.1),
                    boxShadow: theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none',
                }}
            >
                <Scrollbar>
                    <Box mt={3}>
                        <Box
                            mx={2}
                            sx={{
                                width: 52,
                            }}
                        >
                            <Logo width={250} />
                        </Box>
                    </Box>
                    <Divider
                        sx={{
                            mt: theme.spacing(3),
                            mx: theme.spacing(2),
                            background: theme.colors.alpha.trueWhite[10],
                        }}
                    />
                    <SidebarMenu />
                </Scrollbar>
            </SidebarWrapper>
            <Drawer
                sx={{
                    boxShadow: `${theme.sidebar.boxShadow}`,
                }}
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={sidebarToggle}
                onClose={closeSidebar}
                variant='temporary'
                elevation={9}
            >
                <SidebarWrapper
                    sx={{
                        background:
                            theme.palette.mode === 'dark'
                                ? theme.colors.alpha.white[100]
                                : darken(theme.colors.alpha.black[100], 0.5),
                    }}
                >
                    <Scrollbar>
                        <Box mt={3}>
                            <Box
                                mx={2}
                                sx={{
                                    width: 52,
                                }}
                            >
                                <Logo width={200} />
                            </Box>
                            <Box

                                sx={{
                                    ml: 2,
                                    display: { [appConfig.breakMobileView]: 'none', xs: 'inline-block' },
                                    float: 'right'
                                }}
                            >
                                <ThemeSwitch />
                            </Box>
                        </Box>
                        <Divider
                            sx={{
                                mt: theme.spacing(3),
                                mx: theme.spacing(2),
                                background: theme.colors.alpha.trueWhite[10],
                            }}
                        />
                        <SidebarMenu />
                    </Scrollbar>
                </SidebarWrapper>
            </Drawer>
        </>
    );
}

export default Sidebar;
