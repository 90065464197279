import { Auth0Provider } from '@auth0/auth0-react';
import 'nprogress/nprogress.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { SidebarProvider } from './contexts/SidebarContext';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import { auth0Config } from './features/authentication/client/auth0/config/authConfig';
import { ProductProvider } from './contexts/ProductContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <ProductProvider>
            <Auth0Provider {...auth0Config}>
                <Provider store={store}>
                    <HelmetProvider>
                        <SidebarProvider>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </SidebarProvider>
                    </HelmetProvider>
                </Provider>
            </Auth0Provider>
        </ProductProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
