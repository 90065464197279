import { useContext } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PointOfSaleTwoToneIcon from '@mui/icons-material/PointOfSaleTwoTone';
import { Box, Button, List, ListItem, Typography, styled } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import UserInfo from 'src/components/UserInfo/UserInfo';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Can } from 'src/contexts/canContext';
import { Logout } from 'src/features/authentication/components/logout';
import { PriceCheckTwoTone } from '@mui/icons-material';
import { T } from '@transifex/react';
import { AbilityFeature, AbilityKeys } from 'src/config/ability';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

  .MuiListSubheader-root {
    text-transform: uppercase;
    font-weight: bold;
    font-size: ${theme.typography.pxToRem(12)};
    color: ${theme.sidebar.menuItemHeadingColor};
    padding: ${theme.spacing(0, 2.5)};
    line-height: 1.4;
  }

  .MuiTypography-root {
    font-weight: bold;
    font-size: ${theme.typography.pxToRem(12)};
    color: ${theme.sidebar.menuItemColorActive};
    padding: ${theme.spacing(0, 2.5)};
    line-height: 1.4;
  }
  
  .MuiSvgIcon-root {
    color: ${theme.sidebar.menuItemColorActive};
  }
`,
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.palette.text.primary};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.sidebar.menuItemIconColor};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.sidebar.menuItemIconColor};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create(['transform', 'opacity'])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`,
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const auth = useAuth0();

  return (
    <>
      <MenuWrapper>
        <List component='div'>
          <List component='div'>
            <SubMenuWrapper>
              <List component='div'>
                <ListItem component='div'>
                  <Box textAlign='center'>
                    <UserInfo />
                  </Box>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
          <SubMenuWrapper>
            <List component='div'>
              <Can I={AbilityKeys.READ} a={AbilityFeature.PROFILE}>
                {!auth.isLoading && (
                  <ListItem component='div'>
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to='/profile/view/current-user'
                      startIcon={<AccountCircleIcon />}
                    >
                      <T _str="My Profile" />
                    </Button>
                  </ListItem>
                )}
              </Can>
              <Can I={AbilityKeys.READ} a={AbilityFeature.ACCOUNT}>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to='/account'
                    startIcon={<AccountBalanceWalletRoundedIcon />}
                  >
                    <T _str="My Account" />
                  </Button>
                </ListItem>
              </Can>
              <Can I={AbilityKeys.READ} a={AbilityFeature.USERS}>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to='/admin/users'
                    startIcon={<PeopleRoundedIcon />}
                  >
                    <T _str="Users" />
                  </Button>
                </ListItem>
              </Can>
              <Can I={AbilityKeys.READ} a={AbilityFeature.ALL_TRANSACTIONS}>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to='/admin/transactions'
                    startIcon={<PointOfSaleTwoToneIcon />}
                  >
                    <T _str="Transactions" />
                  </Button>
                </ListItem>
              </Can>
              <Can I={AbilityKeys.READ} a={AbilityFeature.ALL_PENDING_WITHDRAWALS}>
                <ListItem component='div'>
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to='/admin/pendingWithdrawals'
                    startIcon={<PriceCheckTwoTone />}
                  >
                    <T _str="Pending Withdrawals" />
                  </Button>
                </ListItem>
              </Can>
            </List>
          </SubMenuWrapper>
        </List>
        <List component='div'>
          <SubMenuWrapper sx={{
            position: 'fixed',
            bottom: 100,
            // width: '100%',
            textAlign: 'center',
          }}>
            <List component='div'>
              <ListItem component='div'>
                <Box textAlign='center'>
                  <Button
                    disableRipple
                    onClick={() => window.open('https://help.playpulse.app/hc/en-gb', '_blank')}
                    startIcon={<HelpOutlineRoundedIcon />}
                  >
                    <T _str="Help" />
                  </Button>
                </Box>
              </ListItem>
              <ListItem component='div'>
                <Box textAlign='center'>
                  <Logout />
                </Box>
              </ListItem>
              <ListItem component='div'>
                <Box textAlign='left'>
                  <Typography variant='body1'>v1.0.10</Typography>
                </Box>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
