//TODO: Define all rest API endpoints here and rather use the tags more in tag validation

/**
 * Enum representing various endpoints.
 */
export enum Endpoint {
    USER = 'User',
    USERS = 'Users',
    USER_TRANSACTIONS = 'UserTransactions',
    USER_BALANCE = 'UserBalance',
    USER_BONUS_BALANCE = 'UserBonusBalance',
    TRANSACTIONS = 'Transactions',
}

/**
 * Array of tag types derived from the values of Endpoint enum.
 */
export const TAG_TYPES: readonly string[] = Object.values(Endpoint);

/**
 * Enum representing tag IDs for partial list and withdrawal request.
 */
export enum TagId {
    PARTIAL_LIST = 'PARTIAL-LIST',
    WITHDRAWAL_REQUEST = 'WITHDRAWAL-REQUEST',
}