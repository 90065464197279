import React, { useState, useEffect } from 'react';
import { Grow } from '@mui/material';

interface Props {
    children: React.ReactElement<any, any>;
    timeout?: number
    repeat?: boolean
}

const GrowChild: React.FC<Props> = ({ children, timeout = 3000, repeat = false }) => {
    const [grow, setGrow] = useState(false);

    useEffect(() => {
        if (repeat) {
            const interval = setInterval(() => {
                setGrow((prevGrow) => !prevGrow);
            }, 2000); // Adjust the interval duration as needed (in milliseconds)

            return () => {
                clearInterval(interval);
            };
        } else {
            setGrow(true)
        }
    }, []);

    return (
        <Grow in={grow} timeout={timeout} easing={repeat ? {
            enter: 'cubic-bezier(0, 3, .8, 1)',
            exit: 'linear'
        } : {
            enter: 'linear',
            exit: 'linear'
        }}>
            {children}
        </Grow>
    );
};

export default GrowChild;
