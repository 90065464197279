type IAppConfig = {
    currency: {
        type: string,
        symbol: string,
    },
    noToast: string[],
    breakMobileView: 'sm' | 'md' | 'lg',
    withdrawalRequestLimit: number
}

const appConfig: IAppConfig = {
    currency: {
        type: 'usd',
        symbol: '$',
    },
    noToast: ['cancelWithdrawal', 'payout', 'requestDeposit', 'requestWithdrawal', 'getUserLocation', 'requestTCPayDeposit', 'requestTcPayWithdrawal', 'getForexSpotPrice'], //don't show toasts for these mutations if success, pending or error, except for requestDeposit, where errors are also disabled
    breakMobileView: 'sm',
    withdrawalRequestLimit: 100, //in Dollars (NOT USED ATM)
};

export default appConfig;
