import { env } from 'src/env';
import { baseApi } from './baseApi.service';
import { Endpoint } from './config/apiTags';

const auth0Api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // ############################## SET ADMIN ROLE ######################################
    makeAdmin: builder.mutation<string, string>({
      query: (userId) => ({
        url: `/auth0/${userId}/`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [{ type: Endpoint.USER, id: arg }, Endpoint.USERS],
    }),
    // ############################## REMOVE ADMIN ROLE ###################################
    revokeAdmin: builder.mutation<string, string>({
      query: (userId) => ({
        url: `/auth0/${userId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: Endpoint.USER, id: arg }, Endpoint.USERS],
    }),
    // ############################## CHANGE PASSWORD ######################################
    resetPassword: builder.mutation<string, string>({
      query: (email) => ({
        url: `https://${env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        method: 'POST',
        body: {
          // eslint-disable-next-line camelcase
          client_id: env.REACT_APP_AUTH0_CLIENTID,
          email: email,
          connection: 'Username-Password-Authentication',
        },
        responseHandler: (response) => response.text(),
      }),
    }),
    // ############################## Resend Verification Email ######################################
    resendVerificationEmail: builder.query<string, string>({
      query: (userId) => ({
        url: `/auth0/${userId}/verify-email`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useMakeAdminMutation, useRevokeAdminMutation, useResetPasswordMutation, useLazyResendVerificationEmailQuery } = auth0Api;