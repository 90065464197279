import { RegexPatterns } from 'src/constants/regex';

export const getEllipsisTxt = (str: string, n = 6) => {
    if (str.length > n) {
        return `${str.substr(0, n)}...`;
    }
    return str;
};

export const addSeparator = (num: string) => num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const currencyFormat = (num: any, symbol: string) =>
    `${symbol}${addSeparator(Number(num).toFixed(2))}`;

export const capitalize = (str: string) => {
    if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else {
        return str;
    }
};

export const expiryFormatterForMMYY = (input: string) => {
    // Only allow numbers and '/'
    input = input.replace(RegexPatterns.digitsAndSlash.pattern, '');
    // Ensure MM is between 1 and 12
    if (input.length > 2) {
        const month = parseInt(input.slice(0, 2));
        if (month < 1 || month > 12) return;
    }
    // Add leading zero to month if necessary
    if (input.length === 2 && input.charAt(0) !== '0' && parseInt(input) > 0 && parseInt(input) < 10) {
        input = '0' + input;
    }
    // Insert '/' after second character
    if (input.length > 2 && input.charAt(2) !== '/') {
        input = input.slice(0, 2) + '/' + input.slice(2);
    }
    return input
};

