/**
 * This module provides the ProductContext and ProductProvider components, which manage
 * the active product configuration and provide it to the application through context.
 */

// TODO: CHANGE THIS TO ZUSTAND

import { FC, useState, createContext, useEffect, Suspense } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { productConfig } from 'src/config/product';
import { AppEnvs, env } from 'src/env';
import { IProducts, IActiveProductConfig, IProductUrls } from 'src/models/product';

/**
 * Represents the shape of the ProductContext.
 */
type ProductContext = {
  product: IActiveProductConfig;
  setCRMProduct: (product: IProducts) => void;
};

/**
 * Create a React context for managing the active product configuration.
 */
export const ProductContext = createContext<ProductContext>({} as ProductContext);

/**
 * Represents the properties that the ProductProvider component accepts.
 */
interface IProductProvider {
  children: React.ReactNode;
}

/**
 * A React component that provides the active product configuration to the application
 * through context.
 *
 * @param {IProductProvider} props - The properties for the ProductProvider component.
 */
export const ProductProvider: FC<IProductProvider> = ({ children }: IProductProvider) => {
  // State to hold the active product configuration
  const [product, setProduct] = useState<IActiveProductConfig>();
  // State to manage loading state
  const [_loading, setLoading] = useState<boolean>(false);

  /**
   * Sets the favicon of the document.
   *
   * @param {string} src - The URL of the favicon.
   */
  const setFavicon = (src: string) => {
    const favicon = document.getElementById('favicon');
    favicon?.setAttribute('href', src);
  }

  useEffect(() => {
    setLoading(true);

    // Determine the CRM product based on the host
    if (window.location.host.includes('pulse')) {
      console.log('on pulse host:', window.location.host)
      setCRMProduct('PULSE');
    } else if (window.location.host.includes('boxwind')) {
      console.log('on boxwind host:', window.location.host)
      setCRMProduct('BOXWIND');
    } else {
      console.log('on local host:', window.location.host)
      setCRMProduct('PULSE');
    }

  }, []);

  /**
   * Get the product URLs based on the current environment.
   *
   * @param {IProducts} product - The selected product.
   * @returns {IProductUrls} - The URLs for the selected product in the current environment.
   */
  const getUrl = (product: IProducts): IProductUrls => {
    const environment = env.REACT_APP_ENV;
    if (environment === AppEnvs.PROD) {
      return productConfig[product].production;
    } else if (environment === AppEnvs.STAGE) {
      return productConfig[product].staging;
    } else if (environment === AppEnvs.DEV) {
      return productConfig[product].development;
    } else if (environment === AppEnvs.LOCAL) {
      return productConfig[product].local;
    } else {
      // TODO: Handle the case when the environment is not correct
      return productConfig[product].development;
    }
  }

  /**
   * Sets the active CRM product configuration.
   *
   * @param {IProducts} product - The selected product.
   */
  const setCRMProduct = (product: IProducts) => {
    const activeProductConfig: IActiveProductConfig = {
      name: productConfig[product].name,
      supportMail: productConfig[product].supportMail,
      displayName: productConfig[product].displayName,
      productUrls: getUrl(product),
      logoDark: productConfig[product].logoDark,
      logoLight: productConfig[product].logoLight,
      privacyPolicyUrl: productConfig[product].privacyPolicyUrl,
      termsAndConditionsUrl: productConfig[product].termsAndConditionsUrl,
    };
    setFavicon(productConfig[product].favicon);
    setProduct(activeProductConfig);
    setLoading(false); // Set loading state to false after configuring the product
  }

  // If product is not available, show a suspense fallback
  if (!product) {
    return <Suspense fallback={<SuspenseLoader />} />;
  }

  // Provide the product context to the children
  return (
    <ProductContext.Provider value={{ product, setCRMProduct }}>
      {children}
    </ProductContext.Provider>
  );
};
