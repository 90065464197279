import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { T } from '@transifex/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from 'src/components/LogoSign';
import GrowChild from 'src/components/growChild/GrowChild';
import { ProductContext } from 'src/contexts/ProductContext';
import useTimeout from 'src/hooks/useTimeout';

export const Login = () => {
    const navigate = useNavigate();
    const { isAuthenticated, isLoading, error, logout, loginWithRedirect } = useAuth0();
    const { product } = useContext(ProductContext)
    const [show, setShow] = useState<boolean>(false)
    const [showLinkError, setShowLinking] = useState<boolean>(false)
    const { clear } = useTimeout(() => handleShow(), 10000)
    const [searchParams] = useSearchParams();

    const handleShow = () => {
        setShow(true)
        clear()
    }

    useEffect(() => {
        if (error) {
            Sentry.captureException(error);
            logout({
                //don't route to app state param from auth0 error - the error is shown on the basecamp app
                openUrl: false,
            })
        } else if (!isLoading && isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, isLoading, error]);

    const linkError = searchParams.get('error_description');

    useEffect(() => {
        //TODO: WE CAN MAYBE GET THIS FROM ERROR AUTH0 AND NOT FROM PARAMS
        if (linkError) {
            setShowLinking(true)
        } else {
            setShowLinking(false)
        }
    }, [searchParams]);

    const loginUser = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        try {
            loginWithRedirect({
                authorizationParams: {
                    // eslint-disable-next-line camelcase
                    redirect_uri: product.productUrls.basecampAuth0RedirectUrl
                },
            });
        } catch (err) {
            toast.error('Oops! An error has occurred! Please try again.');
            console.error(err);
        }
    };

    return (
        <Box height="100vh" >
            <Grid container justifyContent='center' alignItems='center' sx={{ height: '80%' }}>
                <Grid item xs={12}>
                    <GrowChild repeat={true}>
                        <Typography variant='h6' sx={{ mb: 2, textAlign: 'center' }}>
                            <Logo width={270} />
                        </Typography>
                    </GrowChild>
                    <Card sx={{ p: 3, display: show ? 'block' : 'none' }}>
                        <CardContent style={{ alignItems: 'center', justifyContent: 'center' }}>
                            {showLinkError &&
                                <Alert severity='info'>
                                    <Typography variant='h6'>
                                        {linkError}
                                    </Typography>
                                </Alert>
                            }
                            <Box>
                                {show &&
                                    <GrowChild timeout={1000}>
                                        <div>
                                            {!showLinkError && (
                                                <Typography
                                                    variant='h4'
                                                    sx={{ mb: 2, textAlign: 'center' }}
                                                >
                                                    <T _str='It looks like you are not logged in.' />
                                                </Typography>
                                            )}
                                            <Typography
                                                variant='body1'
                                                sx={{ textAlign: 'center' }}
                                            >
                                                <T _str='Log in below to access' />{' '}
                                                {product.displayName}
                                            </Typography>
                                            <CardActions sx={{ justifyContent: 'center' }}>
                                                <Button variant='outlined' onClick={loginUser}>
                                                    <T _str='Login' />
                                                </Button>
                                            </CardActions>
                                        </div>
                                    </GrowChild>
                                }
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </Box >
    );
};