import { useAuth0 } from '@auth0/auth0-react';
import { Send } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    CircularProgress,
    Container,
    Paper,
    Skeleton,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import Logo from 'src/components/LogoSign';
import { Logout } from 'src/features/authentication/components/logout';
import useCheckProfileStatus from 'src/hooks/useCheckProfileStatus';
import { useLazyResendVerificationEmailQuery } from 'src/services/restApi/queries/auth0.service';

const OverviewWrapper = styled(Box)(
    () => `
      overflow: auto;
      flex: 1;
      overflow-x: hidden;
      align-items: center;
  `,
);

const PageContent = () => {
    const auth = useAuth0();
    useCheckProfileStatus();

    const [
        getVerificationEmail,
        { isLoading }
    ] = useLazyResendVerificationEmailQuery();

    const handleResendVerificationEmailRequest = () => {
        if (auth.user?.sub) {
            getVerificationEmail(auth.user.sub)
        } else {
            toast.error('An error occurred while sending your email verification.')
        }

    }



    return (
        <Container maxWidth='md' sx={{ textAlign: 'center' }}>
            <Paper sx={{ p: 3 }}>
                <Typography sx={{ mb: 2 }} variant='h5'>
                    {auth.isLoading ? <Skeleton /> : `${auth.user?.email}.`}
                </Typography>
                <Typography sx={{ mb: 2 }} variant='h3'>
                    {auth.isLoading ? <Skeleton /> : 'Your email address is unverified.'}
                </Typography>
                <Typography
                    sx={{ lineHeight: 1.5, pb: 1 }}
                    variant='body1'
                >
                    {auth.isLoading ? (
                        <Skeleton />
                    ) : (
                        `Please go to your inbox and click on the verification link that was sent to ${auth.user?.email ?? ''}.`
                    )}
                </Typography>
                <Typography variant='body1'>Once verified, you will be able to access the platform.</Typography>
                <Box pt={3}>
                    <Typography variant='subtitle2' sx={{ mb: 1 }}>Haven't received a verification email?</Typography>
                    <LoadingButton
                        loading={isLoading}
                        variant='contained'
                        color='primary'
                        type='submit'
                        onClick={() => handleResendVerificationEmailRequest()}
                        startIcon={<Send />}
                    >
                        Resend
                    </LoadingButton>
                </Box>
                <Box pt={3}>
                    <Typography variant='subtitle2' sx={{ mb: 1 }}>Not your email address or already verified your email? Log out and log back in again.</Typography>
                    <Logout />
                </Box>
            </Paper>
        </Container >
    );
};

function Unverified() {
    const auth = useAuth0();

    return (
        <OverviewWrapper>
            <Helmet>
                <title>Verify Email</title>
            </Helmet>
            <Container maxWidth='lg'>
                <Box display='flex' justifyContent='center' py={5} alignItems='center'>
                    <Logo width={230} />
                </Box>

                {auth.isLoading ? <CircularProgress /> : <PageContent />}

            </Container>
        </OverviewWrapper>
    );
}

export default Unverified;
