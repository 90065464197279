import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ThemeSwitch from 'src/components/ThemeSwitch/ThemeSwitch';
import appConfig from 'src/config/appConfig';

function HeaderNotifications() {
    const navigate = useNavigate();
    return (
        <>
            <Box

                sx={{
                    ml: 2,
                    display: { [appConfig.breakMobileView]: 'inline-block', xs: 'none' },
                }}
            >
                <ThemeSwitch />
            </Box>
            <Box
                sx={{
                    display: { [appConfig.breakMobileView]: 'none', xs: 'inline-block' },
                }}
            >
                <IconButton onClick={() => navigate(-1)} sx={{ mr: 1, float: 'left' }}>
                    <ArrowBack />
                </IconButton>
            </Box>
        </>
    );
}

export default HeaderNotifications;
