import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/hooks';
import useIsCurrentUser from 'src/hooks/useIsCurrentUser';
import Status404 from 'src/pages/Status/Status404/Status404';

export const UserGuard = ({ children }: { children: JSX.Element }) => {
    const auth0user = useAppSelector((state) => state.user);
    const isAdmin = auth0user.user?.dbUser.role === 'ADMIN';
    const { userId } = useParams();

    const { isCurrentUser } = useIsCurrentUser({ userId: userId });
    const canView = isCurrentUser || isAdmin;

    return canView ? (
        children
    ) : (
        <Box mt={10}>
            <Status404 />
        </Box>
    );
};
