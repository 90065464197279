import { useAuth0 } from '@auth0/auth0-react';
import {
    Box,
    CircularProgress,
    Container,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import Logo from 'src/components/LogoSign';
import GrowChild from 'src/components/growChild/GrowChild';
import useCheckProfileStatus from 'src/hooks/useCheckProfileStatus';

const OverviewWrapper = styled(Box)(
    () => `
      overflow: auto;
      flex: 1;
      overflow-x: hidden;
      align-items: center;
  `,
);

const LoadingStatus = () => {
    useCheckProfileStatus();

    return (
        <Container maxWidth='md' sx={{ textAlign: 'center', alignItems: 'center', pt: '30vh' }}>
            <GrowChild repeat={false} timeout={2500}>
                <Typography variant='h6' sx={{ mb: 2, textAlign: 'center' }}>
                    <Logo width={270} />
                </Typography>
            </GrowChild>
        </Container >
    );
};

function LoadingStatusWrapper() {
    const auth = useAuth0();

    return (
        <OverviewWrapper>
            <Helmet>
                <title>Loading...</title>
            </Helmet>
            <Container maxWidth='lg'>

                {auth.isLoading ? <CircularProgress /> : <LoadingStatus />}

            </Container>
        </OverviewWrapper>
    );
}

export default LoadingStatusWrapper;
