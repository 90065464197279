import { defineAbility } from '@casl/ability';
import { featureFlags } from './featureFlags';

const ALAVINIKS_ID = 'google-oauth2|111662888190918509409';

export default function defineAbilityFor(userType: string, userId?: string) {
    return defineAbility((can, cannot) => {
        if (userType === 'admin') {
            //pages //TODO
            can(AbilityKeys.VISIT, AbilityFeature.PROFILE);
            can(AbilityKeys.VISIT, AbilityFeature.ACCOUNT);
            can(AbilityKeys.VISIT, AbilityFeature.ALL_TRANSACTIONS);
            can(AbilityKeys.VISIT, AbilityFeature.ALL_USERS);

            //Features
            //user

            //transactions

            // ################ PROFILE ################
            can(AbilityKeys.READ, AbilityFeature.PROFILE_DETAILS); //can read profile details
            can(AbilityKeys.UPDATE, AbilityFeature.PROFILE_DETAILS); //can read profile details
            can(AbilityKeys.READ, AbilityFeature.PROFILE_DETAILS_ADMIN); //can read admin profile details (status, role)
            can(AbilityKeys.DELETE, AbilityFeature.USER); //can delete user
            can(AbilityKeys.DISABLE, AbilityFeature.USER); //can disable user
            can(AbilityKeys.DEACTIVATE, AbilityFeature.ACCOUNT); //can deactivate account

            // ################ REPORTS ################
            if (featureFlags.role.admin.feature.depositReport) {
                can(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_DEPOSIT_REPORTS);
            } else {
                cannot(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_DEPOSIT_REPORTS);
            }
            if (featureFlags.role.admin.feature.withdrawReport) {
                can(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_WITHDRAWALS_REPORT);
            } else {
                cannot(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_WITHDRAWALS_REPORT);
            }
            if (featureFlags.role.admin.feature.tradesReport) {
                can(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_TRADES_REPORT);
            } else {
                cannot(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_TRADES_REPORT);
            }
            if (featureFlags.role.admin.feature.accountsReport) {
                can(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_ACCOUNTS_REPORT);
            } else {
                cannot(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_ACCOUNTS_REPORT);
            }

            // ################ ACCOUNT ################
            // ### DEPOSITS ###
            if (featureFlags.role.admin.feature.deposit) {
                can(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS);
            } else {
                cannot(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS);
            }
            //revio
            if (featureFlags.role.admin.feature.depositRevio) {
                can(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_REVIO);
            } else {
                cannot(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_REVIO);
            }
            //coinsbuy
            if (featureFlags.role.admin.feature.depositCoinsbuy) {
                can(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_COINSBUY);
            } else {
                cannot(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_COINSBUY);
            }
            //tcPay
            if (featureFlags.role.admin.feature.depositTcPay) {
                can(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_TC_PAY);
            } else {
                cannot(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_TC_PAY);
            }
            // ### WITHDRAWALS ###
            if (featureFlags.role.admin.feature.withdraw) {
                can(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS);
            } else {
                cannot(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS);
            }
            //revio
            if (featureFlags.role.admin.feature.withdrawRevio) {
                can(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_REVIO);
            } else {
                cannot(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_REVIO);
            }
            //coinsbuy
            if (featureFlags.role.admin.feature.withdrawCoinsbuy) {
                can(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_COINSBUY);
            } else {
                cannot(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_COINSBUY);
            }
            //tcPay
            if (featureFlags.role.admin.feature.withdrawTcPay) {
                can(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_TC_PAY);
            } else {
                cannot(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_TC_PAY);
            }

            //authentication

            // ################ SUMSUB ################
            can(AbilityKeys.READ, AbilityFeature.USERS); //read users of platform
            can(AbilityKeys.UPDATE, AbilityFeature.USERS); //update role of user
            can(AbilityKeys.CREATE, AbilityFeature.USERS); //create new user
            can(AbilityKeys.DELETE, AbilityFeature.USERS); //delete users
            can(AbilityKeys.READ, AbilityFeature.ALL_TRANSACTIONS); //delete users
            can(AbilityKeys.READ, AbilityFeature.ALL_PENDING_WITHDRAWALS);
            cannot(AbilityKeys.READ, AbilityFeature.USER_PENDING_WITHDRAWALS);
            can(AbilityKeys.READ, AbilityFeature.STATUS); //free or paid
            cannot(AbilityKeys.READ, AbilityFeature.PROFILE); //admin does not view own account / Profile
            cannot(AbilityKeys.READ, AbilityFeature.ACCOUNT); //admin does not view own account / Profile
            can(AbilityKeys.EDIT, AbilityFeature.USER); //can edit user captured data
        } else {
            //USER
            //pages //TODO
            can(AbilityKeys.VISIT, AbilityFeature.PROFILE);
            can(AbilityKeys.VISIT, AbilityFeature.ACCOUNT);
            cannot(AbilityKeys.VISIT, AbilityFeature.ALL_TRANSACTIONS);
            cannot(AbilityKeys.VISIT, AbilityFeature.ALL_USERS);

            //Features
            //user

            //transactions

            // ################ PROFILE ################
            can(AbilityKeys.READ, AbilityFeature.PROFILE_DETAILS); //can read profile details
            cannot(AbilityKeys.READ, AbilityFeature.PROFILE_DETAILS_ADMIN); //can read admin profile details (status, role)
            cannot(AbilityKeys.DELETE, AbilityFeature.USER); //cannot delete user
            cannot(AbilityKeys.DISABLE, AbilityFeature.USER); //cannot disable user
            cannot(AbilityKeys.DEACTIVATE, AbilityFeature.ACCOUNT); //can deactivate account

            // ################ REPORTS ################
            cannot(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_DEPOSIT_REPORTS);
            cannot(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_WITHDRAWALS_REPORT);
            cannot(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_TRADES_REPORT);
            cannot(AbilityKeys.DOWNLOAD, AbilityFeature.ALL_ACCOUNTS_REPORT);

            // ################ ACCOUNT ################
            // ### DEPOSITS ###
            if (featureFlags.role.user.feature.deposit) {
                can(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS);
            } else {
                cannot(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS);
            }
            //revio
            if (featureFlags.role.user.feature.depositRevio) {
                can(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_REVIO);
            } else {
                cannot(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_REVIO);
            }
            //coinsbuy
            if (featureFlags.role.user.feature.depositCoinsbuy) {
                can(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_COINSBUY);
            } else {
                cannot(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_COINSBUY);
            }
            //tcPay
            if (featureFlags.role.user.feature.depositTcPay || userId === ALAVINIKS_ID) {
                can(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_TC_PAY);
            } else {
                cannot(AbilityKeys.DEPOSIT, AbilityFeature.FUNDS_TC_PAY);
            }
            //tcPay
            if (featureFlags.role.user.feature.withdrawTcPay || userId === ALAVINIKS_ID) {
                can(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_TC_PAY);
            } else {
                cannot(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_TC_PAY);
            }
            // ### WITHDRAWALS ###
            if (featureFlags.role.user.feature.withdraw) {
                can(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS);
            } else {
                cannot(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS);
            }
            //revio
            if (featureFlags.role.user.feature.withdrawRevio) {
                can(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_REVIO);
            } else {
                cannot(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_REVIO);
            }
            //coinsbuy
            if (featureFlags.role.user.feature.withdrawCoinsbuy) {
                can(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_COINSBUY);
            } else {
                cannot(AbilityKeys.WITHDRAW, AbilityFeature.FUNDS_COINSBUY);
            }

            //authentication

            // ################ SUMSUB ################
            cannot(AbilityKeys.READ, AbilityFeature.USERS); //read users of platform
            cannot(AbilityKeys.UPDATE, AbilityFeature.USERS); //update role of user
            cannot(AbilityKeys.CREATE, AbilityFeature.USERS); //create new user
            cannot(AbilityKeys.DELETE, AbilityFeature.USERS); //delete users
            cannot(AbilityKeys.READ, AbilityFeature.ALL_TRANSACTIONS); //delete users
            cannot(AbilityKeys.READ, AbilityFeature.ALL_PENDING_WITHDRAWALS);
            can(AbilityKeys.READ, AbilityFeature.USER_PENDING_WITHDRAWALS);
            cannot(AbilityKeys.READ, AbilityFeature.STATUS); //free or paid
            can(AbilityKeys.READ, AbilityFeature.PROFILE); //user does view own account / Profile
            can(AbilityKeys.READ, AbilityFeature.ACCOUNT); //user does view own account / Profile
            cannot(AbilityKeys.EDIT, AbilityFeature.USER); //cannot edit user captured data
        }
    });
}

export enum AbilityKeys {
    VISIT = 'visit',
    CREATE = 'create',
    READ = 'read',
    UPDATE = 'update',
    EDIT = 'edit',
    DELETE = 'delete',
    DISABLE = 'disable',
    DEACTIVATE = 'deactivate',

    DOWNLOAD = 'download',

    WITHDRAW = 'withdraw',
    DEPOSIT = 'deposit',
}

export enum AbilityFeature {
    USER = 'user',
    USERS = 'users',
    ALL_USERS = 'allUsers',
    ACCOUNT = 'account',
    PROFILE = 'profile',
    STATUS = 'status',
    USER_PENDING_WITHDRAWALS = 'userPendingWithdrawals',
    ALL_PENDING_WITHDRAWALS = 'allPendingWithdrawals',
    ALL_TRANSACTIONS = 'allTransactions',
    FUNDS = 'funds',
    FUNDS_TC_PAY = 'funds-tcpay',
    FUNDS_COINSBUY = 'funds-coinsbuy',
    FUNDS_YELLOWCARD = 'funds-yellowcard',
    FUNDS_REVIO = 'funds-revio',
    ALL_ACCOUNTS_REPORT = 'allAccountsReports',
    ALL_TRADES_REPORT = 'allTradesReports',
    ALL_DEPOSIT_REPORTS = 'allDepositsReports',
    ALL_WITHDRAWALS_REPORT = 'allWithdrawalsReports',
    PROFILE_DETAILS = 'profileDetails',
    PROFILE_DETAILS_ADMIN = 'profileDetailsAdmin',
}
