import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useAppSelector } from '../hooks/hooks';
import { selectTheme } from '../store/theme/themeSlice';
import { themeCreator } from './base';
import { ProductContext } from 'src/contexts/ProductContext';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ThemeContext = React.createContext((themeName: string): void => { });

interface IThemeProviderWrapper {
    children: ReactNode;
}

const ThemeProviderWrapper: React.FC<IThemeProviderWrapper> = (props: any) => {
    const curThemeName = useAppSelector(selectTheme);
    const { product } = useContext(ProductContext)
    const [gameThemeName, setGameThemeName] = useState('DEFAULT')

    const setThemeName = (themeName: string): void => {
        localStorage.setItem('basecampThemeType', themeName)
    };

    useEffect(() => {
        let theme;
        if (curThemeName === 'dark') {
            theme = `${product.name.toUpperCase()}`
        } else {
            theme = `${product.name.toUpperCase()}_LIGHT`
        }
        setGameThemeName(theme);
        setThemeName(curThemeName);
    }, [curThemeName]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeContext.Provider value={setThemeName}>
                <ThemeProvider theme={themeCreator(gameThemeName)}>{props.children}</ThemeProvider>
            </ThemeContext.Provider>
        </StyledEngineProvider>
    );
};

export default ThemeProviderWrapper;
