import { Middleware, MiddlewareAPI, isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import appConfig from 'src/config/appConfig';
export const rtkQueryMiddleware: Middleware = (_api: MiddlewareAPI) => (next) => async (action) => {
    if (isRejectedWithValue(action)) {
        //Error toast messages are turned off on certain endpoints
        if (!appConfig.noToast.includes(action.meta.arg.endpointName)) {
            const status = action.payload.status;
            const message = action?.payload?.data?.message;
            toast.dismiss();
            if (status === 401) {
                toast.error(message ?? 'Not authorised', {
                    autoClose: 10000,
                });
                // window.location.href = '/auth/login';
            } else if (status === 403) {
                toast.error(message ?? 'User not authorised to perform this action.');
            } else if (status === 500) {
                toast.error(message ?? 'Internal server error.');
            } else if (status === 404) {
                toast.error(message ?? 'Resource not found.');
            } else if (status === 400) {
                toast.error(action?.payload?.data?.errorMessage ?? 'An error has occurred', {
                    autoClose: 10000,
                });
                // add more if statements for other explicit status code handling
            } else {
                toast.error(
                    action?.payload?.data?.errorMessage ??
                    'An unknown error has occurred.',
                    { autoClose: 10000 },
                );
            }
        }
    } else if (action?.meta?.arg?.type === 'mutation') {
        if (!appConfig.noToast.includes(action.meta.arg.endpointName)) {
            //there are some mutations we don't want to show toasts for
            if (action.type.endsWith('/fulfilled')) {
                toast.dismiss();
                toast.success(action?.payload?.data?.message ?? 'Success!', {
                    hideProgressBar: true,
                });
            } else if (action.type.endsWith('/pending')) {
                toast.info(`Requesting ${action?.meta?.arg?.endpointName}...`, {
                    hideProgressBar: true,
                });
            }
        }
        // add more if statements for other successful actions
    }
    return next(action);
};
