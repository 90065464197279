import { useAuth0 } from '@auth0/auth0-react';
import { LogoutTwoTone } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { T } from '@transifex/react';
import { useContext } from 'react';
import { ProductContext } from 'src/contexts/ProductContext';

export const Logout = () => {
    const { logout } = useAuth0();
    const { product } = useContext(ProductContext)

    const logoutUser = async () => {
        localStorage.removeItem('kycStatusSeen');
        try {
            logout({
                logoutParams: {
                    returnTo: product.productUrls.gameUrl
                }
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <LoadingButton variant='contained' onClick={() => logoutUser()} startIcon={<LogoutTwoTone />} >
            <T _str='Logout' />
        </LoadingButton>
    );
};
