import { FirestoreFilteringAndPaginationOptions } from 'src/backend-models/firebase/filtering-and-pagination-options';
import { Result, ResultWithValue } from 'src/backend-models/result-models/results';
import {
    CompleteUserProfileIM,
    CompleteUserProfileRequestBodyDTO,
    UserAccountBalanceVM,
    UserBonusBalanceSchemaVM,
    UserVM,
} from 'src/backend-models/users/user-schema';
import { syncUserSlice } from 'src/features/authentication/services/syncUserSlice';
import { IUpdateUser } from 'src/models/users';
import store, { RootState } from 'src/store/store';
import { baseApi } from './baseApi.service';

const usersApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        // ############################## GET USER BY ID ###################################
        getUserById: builder.query<ResultWithValue<UserVM>, { userId: string }>({
            query: ({ userId }: { userId: string }) => ({
                url: `/users/${userId}/`,
                method: 'GET',
            }),
            providesTags: (_result, _error, arg) => [{ type: 'User', id: arg.userId }],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    //every time the user updates their own profile, we just update our userSlice db user as well with updated values
                    const { data } = await queryFulfilled;
                    const userId: string | undefined = (store.getState() as RootState)?.user?.user
                        ?.sub;
                    if (userId && arg.userId === userId) {
                        const user = (store.getState() as RootState).user.user;
                        dispatch(
                            syncUserSlice({
                                user: { ...user, dbUser: data.value },
                            })
                        );
                    }
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        // ############################## GET ALL USERS ####################################
        getUsers: builder.query<
            ResultWithValue<{ users: UserVM[]; count: number }>,
            FirestoreFilteringAndPaginationOptions
        >({
            query: ({
                limit,
                startAfter,
                orderBy,
                filters,
            }: FirestoreFilteringAndPaginationOptions) => ({
                url: '/users/',
                method: 'GET',
                params: {
                    limit: limit,
                    startAfter: JSON.stringify(startAfter),
                    orderBy: JSON.stringify(orderBy),
                    filters: JSON.stringify(filters),
                },
            }),
            providesTags: (result, _error, _page) =>
                result
                    ? [
                          // Provides a tag for each post in the current page,
                          // as well as the 'PARTIAL-LIST' tag.
                          ...result.value.users.map(({ id }) => ({ type: 'Users' as const, id })),
                          { type: 'Users', id: 'PARTIAL-LIST' },
                      ]
                    : [{ type: 'Users', id: 'PARTIAL-LIST' }],
        }),
        // ############################## UPDATE USER ######################################
        updateUser: builder.mutation<Result, { userId: string; body: IUpdateUser }>({
            query: ({
                userId,
                body,
            }: {
                userId: string;
                body: {
                    name: string;
                    surname: string;
                    email: string;
                    number: string;
                    picture: string;
                };
            }) => ({
                url: `/users/${userId}/`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: 'User', id: arg.userId }, 'Users'],
        }),
        // ############################## DELETE USER ######################################
        deleteUser: builder.mutation<Result, { userId: string }>({
            query: ({ userId }: { userId: string }) => ({
                url: `/users/${userId}/`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: 'User', id: arg.userId }, 'Users'],
        }),
        // ############################## DISABLE USER TOGGLE ######################################
        disableUserToggle: builder.mutation<Result, { userId: string; isDisabled: boolean }>({
            query: ({ userId, isDisabled }: { userId: string; isDisabled: boolean }) => ({
                url: `/users/${userId}/disable`,
                params: { isDisabled: isDisabled },
                method: 'PATCH',
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: 'User', id: arg.userId }, 'Users'],
        }),
        // ############################## MFA USER TOGGLE ######################################
        userMFAToggle: builder.mutation<Result, { userId: string; mfaEnabled: boolean }>({
            query: ({ userId, mfaEnabled }: { userId: string; mfaEnabled: boolean }) => ({
                url: `/users/${userId}/mfa`,
                params: { mfaEnabled: mfaEnabled },
                method: 'PATCH',
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: 'User', id: arg.userId }, 'Users'],
        }),
        // ############################## GET USER ACCOUNT BALANCE ####################################
        getUserAccountBalance: builder.query<ResultWithValue<UserAccountBalanceVM>, string>({
            query: (userId) => ({
                url: `/users/${userId}/account-balance`,
                method: 'GET',
            }),
            providesTags: ['UserBalance'], //TODO: this is never auto invalidated
        }),
        // ############################## GET USER BONUS BALANCE ####################################
        getUserBonusBalance: builder.query<ResultWithValue<UserBonusBalanceSchemaVM>, string>({
            query: (userId) => ({
                url: `/users/${userId}/bonus-balance`,
                method: 'GET',
            }),
            providesTags: ['UserBonusBalance'], //TODO: this is never auto invalidated
        }),
        // ############################## COMPLETE USER PROFILE ####################################
        completeUserProfile: builder.mutation<
            Result,
            { userId: string; body: CompleteUserProfileRequestBodyDTO }
        >({
            query: ({ userId, body }: { userId: string; body: CompleteUserProfileIM }) => ({
                url: `/users/${userId}/complete-profile`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: 'User', id: arg.userId }, 'Users'],
        }),
    }),
});

export const {
    useGetUserByIdQuery,
    useUpdateUserMutation,
    useGetUsersQuery,
    useLazyGetUsersQuery,
    useDeleteUserMutation,
    useGetUserAccountBalanceQuery,
    useDisableUserToggleMutation,
    useUserMFAToggleMutation,
    useCompleteUserProfileMutation,
    useGetUserBonusBalanceQuery,
} = usersApi;
