const pages = {
    auth: {
        root: 'auth',
        login: {
            name: 'login',
            path: '/auth/login',
        },
        register: {
            name: 'register',
            path: '/auth/register',
        },
        loginAdmin: {
            name: 'loginAdmin',
            path: '/auth/admin/login',
        },
    },
    home: {
        name: 'home',
        path: '/home',
    },
    profileView: {
        name: 'profile/view',
        path: '/profile/view/:userId',
    },
    profileEdit: {
        name: 'profile/edit',
        path: '/profile/edit/:userId',
    },
    account: {
        name: 'account',
        path: '/account',
    },
    admin: {
        root: 'admin',
        transactions: {
            name: 'transactions',
            path: '/admin/transactions',
        },
        users: {
            name: 'users',
            path: '/admin/users',
        },
        pendingWithdrawals: {
            name: 'pendingWithdrawals',
            path: '/admin/pendingWithdrawals',
        },
    },
    users: {
        name: 'users',
        path: '/users',
    },
    sumsub: {
        name: 'sumsub',
        path: '/sumsub',
    },
    unverified: {
        name: 'unverified',
        path: '/unverified',
    },
    profileIncomplete: {
        name: 'profileIncomplete',
        path: '/profileIncomplete',
    },
    status: {
        root: 'status',
        statusComingSoon: {
            name: 'coming-soon',
            path: '/status/coming-soon',
        },
        statusMaintenance: {
            name: 'maintenance',
            path: '/status/maintenance',
        },
        status404: {
            name: '404',
            path: '/status/404',
        },
        status500: {
            name: '500',
            path: '/status/500',
        },
        statusTransactionSuccess: {
            name: 'success',
            path: '/status/success',
        },
        statusTransactionFailure: {
            name: 'failure',
            path: '/status/failure',
        },
        statusTransactionCancel: {
            name: 'cancel',
            path: '/status/cancel',
        },
    },
};

export default pages;
